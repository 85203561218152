.nav-link.active {
    color: #004256;
    background-color: #a4c4ce57;
    text-decoration: none;
  }
  .header-panel {
    background-color: #004256;
  }
  .nav-link:hover {
    background-color: #a4c4ce57;
    color: #004256;
  }
  .activeIcon svg {
    color: #004256;
  }
  
  .logout:hover {
    background-color: #a4c4ce57 !important;
    color: #004256;
  }
  .image {
    max-width: 20%;
    margin-right: 1.5rem;
    margin-top: 10px;
  }
  .navigation_block {
    height: calc(100vh - 7rem);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
  }
  
  .navigation_block ul {
    width: 100%;
  }
.top-bar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    align-items: center;
}

.search-input {
    width: 60% !important;

    &:focus {
        border-color: #004256 !important;
    }
}

.rdt_TableHead {
    .rdt_TableCol {
        background-color: #004256;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        flex-grow: 1;
        max-width: 100%;
        /* min-width: 200px; */
        width: 100%;

        &:nth-child(1) {
            width: 100%;
            max-width: 70px;
            min-width: auto;
        }
    }
}

.common-table {
    .rdt_TableBody {
        .rdt_TableRow {
            &:hover {
                background-color: #0046561a;
            }

            .rdt_TableCell {
                flex-grow: 1;
                max-width: 100%;
                /* min-width: 100%; */
                width: 100%;

                &:nth-child(1) {
                    width: 100%;
                    max-width: 70px;
                    min-width: auto;
                }
            }
            
        }
    }
}

.add-model{
    /* display: none !important; */
    visibility: hidden !important;
}
.forget_img {
  width: 24%;
}

.forget-password-main {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.forget-password-main p {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.fpm_input {
  max-width: 100%;
  margin: auto;
  margin-bottom: 1rem;
  width: 30rem;
}

.fpm_input:focus {
  border: 1px solid #004256 !important;
}

.fpm_inner_block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.link-connect {
  display: flex;
  justify-content: center;
  color: #004256;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .fpm_input {
    max-width: 80%;
  }

  .forget_img {
    width: 55%
  }
}
.centered-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-image {
  max-height: 100%;
  max-width: 100%;
}
.image_404 {
  max-width: 40%;
}

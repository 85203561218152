/* common css start */

* {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  outline: none;
  color: #444;
}

a:hover {
  color: #444;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

a:hover,
a:focus,
input,
textarea {
  text-decoration: none;
  outline: none;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

p {
  margin-bottom: 0px;
  width: 100%;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.hid {
  display: none;
}

.top-mar {
  margin-top: 15px;
}

.h-100 {
  height: 100%;
}

::placeholder {
  color: #747f8a !important;
  font-size: 13px;
  opacity: 0.5 !important;
}

.container-fluid {
  padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  color: #545454;
}

.cp {
  cursor: pointer;
}

.center_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* common css end */

.content-wrapper {
  margin-left: 17rem;
  margin-top: 5rem;
}

.navMenu {
  background-color: #0a84ee00 !important;
}

.common-table .leUHeK {
  justify-content: center !important;
}

@media (max-width: 600px) {
  .content-wrapper {
    transition: margin-left 0.5s ease-in-out;
    margin-left: 1rem;
  }
}

.form-field {
  display: flex;
  flex-direction: column;
}

.form-field input {
  margin-bottom: 8px;
}

/* common button css start */

.custom_btn {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  width: 100%;
  border-radius: 6px;
  font-weight: 600;
  color: white;
  background-color: #004256;
  border-color: #004256;
}

.custom_btn:hover {
  background-color: #265e6f;
  border-color: #004256;
  color: white;
}

.custom_btn:active {
  background-color: #004256;
  border-color: #004256;
}

.custom_btn:focus {
  background-color: #004256;
  border-color: #004256;
  box-shadow: none;
}

/* common button css end  */

/* common form error validation css start */

.form-error {
  color: red;
  margin: 0 0 16px 5px !important;
  text-align: left !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

/* common form error validation css end  */

.container {
  max-width: 1170px;
  margin-top: 9rem;
}

@media screen and (max-width: 575px) {
  .container {
    padding: 10px 15px;
  }
}

/* custom eye button on input */
.position-relative {
  position: relative;
}

.eye-block {
  position: absolute;
  right: 10px;
  top: 8px;
}

html {
  background: #fafafa;
  font-size: 14px;
  width: 100%;
  height: 100%;
}

input[type="text"] {
  /* Basic styling */
  width: 100%;
  /* Ensure full width */
  padding: 10px 15px;
  /* Comfortable padding */
  border: 1px solid #ccc;
  /* Subtle border */
  border-radius: 4px;
  /* Rounded corners */
  font-size: 16px;
  /* Readable font size */
  box-sizing: border-box;
  /* Consistent sizing */

  /* Placeholder styling */
  color: #999;
  /* Lighter placeholder text */

  /* Focus state styling */
  &:focus {
    outline: none;
    /* Remove default outline */
    border-color: #555;
    /* Highlight border */
  }

  /* Optional styling details */
  background-color: #fff;
  /* Slight background color */
  transition: border-color 0.2s ease-in-out;
  /* Smooth border color change */

  &.search-input {
    width: 40%;
  }
}

input[type="number"] {
  /* Basic styling */
  width: 100%;
  /* Ensure full width */
  padding: 10px 15px;
  /* Comfortable padding */
  border: 1px solid #ccc;
  /* Subtle border */
  border-radius: 4px;
  /* Rounded corners */
  font-size: 16px;
  /* Readable font size */
  box-sizing: border-box;
  /* Consistent sizing */

  /* Placeholder styling */
  color: #999;
  /* Lighter placeholder text */

  /* Focus state styling */
  &:focus {
    outline: none;
    /* Remove default outline */
    border-color: #555;
    /* Highlight border */
  }

  /* Optional styling details */
  background-color: #f5f5f5;
  /* Slight background color */
  transition: border-color 0.2s ease-in-out;
  /* Smooth border color change */

  &.search-input {
    width: 40%;
  }
}

/* Optional container styling */
.search-container {
  position: relative;
  /* Allow for icon placement */
  display: flex;
  /* Flexbox layout for icon alignment */
  align-items: center;
  /* Vertically center elements */
}

.search-icon {
  position: absolute;
  /* Position independently */
  right: 15px;
  /* Adjust for spacing */
  top: 50%;
  /* Vertically center */
  transform: translateY(-50%);
  /* Fine-tune vertical alignment */
  color: #999;
  /* Lighter icon color */
}

.image-preview {
  height: 100px;
  width: 100px;
}

.preview-container {
  position: relative;
}

.remove-button {
  position: absolute;
  top: 0;
  right: 0;
}

.floorPlan {
  width: 35%;
}

/* Global Stylesheet */
/* For Table */
.fNLvxm {
  display: unset !important;
}

.rdt_TableCell:nth-child(1),
.rdt_TableCol:nth-child(1) {
  min-width: 70px !important;
  max-width: 70px !important;
}

.rdt_TableCell,
.rdt_TableCol {
  min-width: 120px !important;
}

.fpBGKh:hover::-webkit-scrollbar-thumb {
  background-color: #004256;
}

.fpBGKh:hover::-webkit-scrollbar-track {
  border-color: #004256;
}

.fpBGKh::-webkit-scrollbar-track {
  border: 1px solid #3d5962a5;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.fpBGKh::-webkit-scrollbar {
  height: 7px;
  background-color: #f5f5f5;
}

.fpBGKh::-webkit-scrollbar-thumb {
  background-color: #3d5962a5;
  border-radius: 10px;
}

img.image-block {
  box-shadow: inset 0px 0px 12px 8px #004256;
  width: 50px !important;
  height: 50px;
  border-radius: 10px;
  padding: 3px;
  margin: 4px;
  object-fit: cover;
  object-position: center;
}

img.image-block.imgSvg {
  box-shadow: inset 0px 0px 3px 3px #004256;
  padding: 10px;
}

body {
  overflow: unset !important;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar-track {
  border: 1px solid #3d59625d;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar-thumb {
  background-color: #3d59625d;
  border-radius: 10px;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper:hover::-webkit-scrollbar-thumb {
  background-color: #004256;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper:hover::-webkit-scrollbar-track {
  border-color: #004256;
}

/* Modal Design */
.react-responsive-modal-modal {
  border-radius: 30px;
  overflow: auto !important;
  overflow-y: auto !important;
}

.react-responsive-modal-modal h2 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-left: 10px !important;
  font-weight: 600;
  font-size: 24px;
  color: #004256;
}

.react-responsive-modal-modal div.d-flex.ms-5 {
  margin-left: 10px !important;
}

.react-responsive-modal-modal button.react-responsive-modal-closeButton {
  width: 20px !important;
}

.react-responsive-modal-modal .comman-grey p {
  font-size: 16px;
  font-weight: 600;
}

.react-responsive-modal-modal input {
  color: #000;
}

button.btn.btn-primary {
  background-color: #004256;
}

.nav-pills a.nav-link {
  background-color: transparent !important;
  border: 1px solid #004256;
  color: #004256 !important;
}

.nav-pills a.nav-link.activeTab {
  background-color: #004256 !important;
  border: 1px solid #004256;
  color: #fff !important;
}

form.aboutus_form .row_block {
  display: flex;
  gap: 20px;
}

form.aboutus_form .row {
  width: 50%;
}

button.swal2-confirm.swal2-styled {
  background-color: #004256 !important;
  outline: none !important;
  box-shadow: none !important;
}

/* Component wise stylesheet */

.payment-info {
  border-radius: 15px;
  background: var(--white, #fff);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
}

.comman-midium-blue {
  color: var(--blue, #1c445f);
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.comman-small-blue {
  color: var(--blue, #1c445f);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.edit-image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

/* .react-responsive-modal-container .card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border: 0px solid transparent;
}
.react-responsive-modal-container .card.custom-block1{
  flex-direction: column;
  justify-content: flex-start;
}
.react-responsive-modal-container img {
  width: 350px;
  padding: 7px;
  margin: 0 auto;
  border-radius: 30px;
  margin-top: 15px;
  margin-left: 15px;
  border: 2px solid #004256;
  box-shadow: 0px 0px 15px 2px #004256b8;
} */
.react-responsive-modal-container img.custom1 {
  width: 200px;
  margin: 0 auto;
}

.react-responsive-modal-container .card {
  border: 0px solid transparent;
  text-align: justify;
}
.react-responsive-modal-container img {
  width: 350px;
  margin: 0 auto;
}
.payment-info .cp {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 30px;
  border: 1px solid #004256;
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
  padding: 0;
  padding-left: 8px;
  background: #004256;
  color: #fff;
}
.user-panel {
  width: 100%;
  padding: 0 30px 0 10px !important;
}
.user-panel .image img {
  width: 70px;
  display: block;
  border-radius: 50%;
  height: 40px;
  object-fit: cover;
  object-position: center;
}
.profileActive {
  color: #004256;
  background-color: #a4c4ce57;
  text-decoration: none;
  height: 62px;
  padding: 0;
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-hyum1k-MuiToolbar-root {
  padding: 0;
}
.payment-info .text-green {
  color: green;
}
.payment-info .text-red {
  color: red;
}
.payment-info .text-blue {
  color: blue;
}
.payment-info .text-primary {
  color: #004256;
}
.input-type label {
  margin-right: 10px !important;
  margin-top: 5px;
}
.input-type input {
  margin-right: 10px !important;
}
.preview-container img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  border: 2px solid #004256;
  box-shadow: 0px 0px 5px 5px #00425625;
  margin-right: 10px;
}
.payment-info.m-4.w-100 {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0px 0px 5px 5px #00425625;
}
.payment-info .user-info {
  padding-left: 30px;
  padding-top: 15px;
}

.payment-info.m-4.w-100 .comman-small-blue p {
  background: #004256;
  color: #fff;
  padding: 15px;
  text-align: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.MuiTypography-root.MuiTypography-h6.MuiTypography-noWrap.css-8u39c-MuiTypography-root {
  padding-left: 30px;
}

.text-black {
  color: black !important;
}

textarea {
  height: 142px;
  width: 100%;
}

@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Arimo:300,400,400italic,700,700italic);

.form-login-body {
  background-color: #f1f1f1ad !important;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: #6a6a6a;
  overflow-x: hidden;
  height: 100vh;
}

@media screen and (max-width: 767px) {
  .form-login-body .login-body .login-text {
    padding-top: 13px;
  }
}

.input_fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-login-body .login-body .login-text h4 {
  font-weight: 600;
  font-size: 2.8rem;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  color: #265e6f;
}

.form-login-body .login-body .login-text .form-control {
  max-width: 100%;
  border-radius: 6px;
}

.form-control:focus {
  box-shadow: none !important;
  border: 0px;
}

.form-control {
  background-color: #ffffff;
  margin-bottom: 20px;
}

.form-login-body .login-body .login-text p {
  font-weight: 600;
  font-size: 0.87rem;
  margin-top: 15px;
  text-align: center;
}

.form-login-body .login-body .login-text a {
  display: flex;
  justify-content: right;
  margin: 5px 0 5px 0;
}

.form-login-body .login-body .login-img img {
  max-width: 100%;
}

/* ===================================== Responsive Style CSS ==================================== */
@media screen and (max-width: 767px) {
  .form-login-body .login-body .login-text {
    margin: 1rem;
  }
}